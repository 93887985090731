<template>
  <div
    class="d-flex justify-content-center align-items-center flex-column wrapper-main"
  >
    <EmptyData description="404" />
    <h2 class="h2 font-bold text-color-secondary">
      ¡Página no encontrada!
    </h2>
  </div>
</template>

<script>
import EmptyData from "@/components/General/EmptyData";

export default {
  name: "404View",
  components: {
    EmptyData
  }
};
</script>

<style lang="scss" scoped></style>
