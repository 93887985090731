<template>
  <el-empty :description="description"></el-empty>
</template>

<script>
export default {
  name: "EmptyData",
  props: {
    description: {
      default: "Aún no hay información en esta sección"
    },
    imageURL: {
      default: null
    },
    imageSize: {
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
